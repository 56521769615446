@mixin doclink {
    background-color: $bannerbg;  
    color: $bannerfg;
    text-decoration: none;
    display: inline-block;
    padding: 1px 5px;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 5px;
  }

@mixin bigdoclink {
    background-color: $bannerbg;  
    color: $bannerfg;
    text-decoration: none;
    display: inline-block;
    padding: 1px 10px;
    font-weight: normal;
    font-size: 20px;
    margin-bottom: 5px;
  }

article.docs {
  @import "zumerocore";

  h1.title, h2.title, h3.title, h4.title, h5.title, h6.title {
    float: none;
    display: block;
    background-color: $articleheaderbg;
    color: $articleheaderfg;
    border-bottom: 1px solid #999;
    margin-bottom: .25em;
    margin-top: 1em;
    width: auto;
    padding: 1px 6px;
  }
}

.doclist {
    padding: 0;
    margin: 10px 0 20px;

    h3 {
      color: $listheadfg;
      background-color: $listheadbg;
      padding: 3px;
      margin-top: 0;
    }

    ul {
      margin-bottom: 0;
      padding: 1em .5em 0;
    }

    input[type=submit] {
      @include doclink;
      border: 2px solid $bannerbg;  

    }

    input[type=email], input[type=submit] {
      padding: 5px;
    }

  @media only screen and (min-width: 960px) {
    width: 430px;
    float: left;
    margin: 10px 15px 10px 0;
  }
}

.evalinput {
    padding: 0;
    margin: 10px 0 5px;

    input[type=submit] {
      @include doclink;
      border: 2px solid $bannerbg;  
    }

    input[type=email], input[type=submit] {
      padding: 5px;
      margin: 5px;
    }

  @media only screen and (min-width: 960px) {
    width: 600px;
    margin: 10px 10px 5px 0;
  }
}

ul.featurelist {
  padding: 0 1.5em;
  font-size: 90%;
  margin-bottom: 1em;

  li {
    list-style: disc;
  }
}

.mediaobject {
  img {
    border: 1px solid #CCC; 
  }

  margin-bottom: 1em;
}

.docheader {
  font-weight: bold;
}


.section-textbox .doclist {

  a, a:visited, a:active, a:link, a:hover {
    @include doclink;
  }

  a.plain, a.plain:visited, a.plain:active, a.plain:link, a.plain:hover {
      background-color: transparent;  
    color: inherit;
    text-decoration: none;
    display: inline;
    padding: 0;
    font-weight: bold;
    font-size: inherit;
    margin-bottom: 0;
  }
}

a.doclink {
  &, &:visited, &:link, &:active, &:hover {
    @include doclink;
  }
}

a.bigdoclink {
  &, &:visited, &:link, &:active, &:hover {
    @include bigdoclink;
  }
}

ul.things {
  margin-bottom: 1em;
  padding: 5px;
  background-color: lighten($graybg, 5%);
}

// table of contents

.maruku_toc, .toc {
  font-variant: small-caps;
  font-size: 90%;

  a, a:link, a:visited, a:active {
    text-decoration: none;
    color: $toclink;
  }

  a:hover {
    text-decoration: underscore;
  }
}


// getting started

.steplist {
  padding: 0;
}

.startstep {
  list-style: none;
  overflow: auto;
  margin-bottom: 1em;
  margin-left: 0;  
  box-sizing: border-box;

  @media only screen and (min-width: 33em) {
      padding-left: 15em;
  }
}

.steps li + li {
  margin-top: 0.5em;
}

.titlelink {
  text-transform: capitalize;
  display: block;
//  width: 15em;

  @media only screen and (min-width: 33em) {
      float: left;
    margin-left: -15em;
  }

}

.shot, aside {
  border-radius: 0.3em;
  box-shadow: rgba(0,0,0,0.15) 0 1px 4px;
  box-sizing: border-box;
  border: white 0.5em solid;  
  margin-bottom: 1em;
  max-width: 100%;
  display: block;
}

aside
 {
    padding: 0.4em 0.8em;
    background-color: $asidebg;
    color: $asidefg;
    font-weight: lighter;
    letter-spacing: 1px;

  @media only screen and (min-width: 40em) {
    float: right;
    max-width: 15em;
    margin-left: 2em;
    margin-right: 1.5em;
  }
}

.faqlink, .sampletabheader {
  background-color: $headerbg;
  color: $headerfg;
  margin-bottom: 0.5em;
  padding: 0.25em 0.5em;
 }

.sampletabheader {
  display: inline-block;
  border-right: 1px solid $tabborder;

  &.active {
    background-color: $activetab;
  }

  &.inactive {
    color: $inactivetab;
  }
}

.inactivetab {
  display: none;
}

.oldnotes {
  margin-top: 1em;
  padding: .5em 1em;
  background-color: $headerbg;
  color: $headerfg;

  a, a:link, a:active, a:visited {
    color: $headerfg;
  }
}

.meta {
  ul {
    list-style: square inside;
  }
}

.others {
  font-style: italic;
  text-align: right;
}

.menuitem {
  font-family: "Bitstream Vera Sans Mono", Consolas, Courier, monospace;
  font-weight: bold;
  padding: 2px;
}

.note {
  background-color: darken($bgcolor, 20%);
  color: $fgcolor;

  p {
    padding: 2px;
  }
}

.guilabel {
  font-weight: bold;
}

.guimenu, .guimenuitem, .guibutton {
  border: 1px solid darken($bgcolor, 30%);
  padding: 3px;
  border-radius: 3px;
}

.example {
  background-color: darken($bgcolor, 20%);
  .title {
    color: $fgcolor;
  }
}