.customer {
    h3, h3 > a, h3 > a:link, h3 > a:visited, h3 > a:active {
      color: $listheadfg;
      background-color: $listheadbg;
      padding: 3px;
      text-decoration: none;
    }

    h3 {
    	margin-bottom: 1em;
    }
}

.custlogo {
	float: left;
	margin: 0 1em 1em 0;
}

.custlist {
	margin-left: 30px;
	margin-top: 10px;
}

.custlistlogo {
	margin: 20px;
}

