$carouselheight: 225;
$smallcarouselheight: #{$carouselheight}pt;
$bigcarouselheight: #{$carouselheight}px;

.carousel-active {
	z-index: 1;
}

.secondary-message.carousel {
	box-sizing: border-box;
	height: $smallcarouselheight;
	overflow: hidden;
	padding: 0;

	@media only screen and (min-width: 480pt) {
		height: $bigcarouselheight;	
	}
}

.secondary-message.carousel > div {
	box-sizing: border-box;

	height: $smallcarouselheight;

	@media only screen and (min-width: 480pt) {
		height: $bigcarouselheight;	
	}
}

.carousel-nav {
	float: none;
	width: 100%;
	position: absolute;
	bottom: 0;
	margin-top: 0;
	z-index: 1000;

	a {
		padding: 0;
		position: absolute;
		bottom: 0;
 		text-indent: -9999px;
		background-repeat: no-repeat;
		display: block;
		width: 13pt;
		height: 21pt;
		opacity: 0.3;

		&:hover {
			opacity: 1;
		}
 
		&.prev {
			left: 0;
			background-image: url(/img/sprites/arrow-left.png);
		}

		&.next {
			right: 0;
			background-image: url(/img/sprites/arrow-right.png);
		}
	}
}

.carousel {
	a, a:link, a:active, a:visited {
		color: $fgcolor;
	}
}
