
pre.screen { 
    background-color: $screenbg; 
    padding:0.15in; 
    white-space: pre;
    word-wrap: normal;
    overflow: auto;
}

span.screen_emphasis { 
    color: $screenbold;
}

span.no_line_breaks {   
    white-space:nowrap;
}

.footnote p {
    font-size: 75%;
}

p.faq_question
{
    font-weight: bold;
}

p.faq_answer
{
    font-style: italic;
    margin-left: 0.5in;
}

span.italic { 
    font-style:italic; 
}

span.bold { 
    font-weight:bold; 
}

