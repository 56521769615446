.permalink {

	a, a:link, a:active, a:visited {
		text-decoration: none;
		color: $permalink;
	}

	margin-top: 0;
}

.postdate {
	background-color: $postdatebg;
	color: $postdatefg;
	font-size: 90%;
	margin-bottom: 0;
	padding: 3px 10px;
}

.layout-post .section-textbox ul, ul.plain {
	list-style-type: circle;
	margin-bottom: 1em;
	padding: 0 0 0 1em;
}

.layout-post {
	pre {
		overflow: auto;

		& > code {
		    white-space: pre;
		    word-wrap: normal;
		    overflow-wrap: normal;
		    overflow: visible;
		}
	}
}

