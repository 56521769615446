@mixin gradient($from, $to) {
  /* fallback/image non-cover color */
  background-color: $from;
 
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient($from, $to);
 
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($from), to($to));
 
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient($from, $to);
 
  /* Opera 11.10+ */
  background-image: -o-linear-gradient($from, $to);
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}