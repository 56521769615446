.secondary-message .pull {
	font-style: italic;
	font-size: 70%;
	margin-top: 30px;
}

.logo {
	display: block;
	margin-top: .5em;
}

.secondary-message a.logo:hover {
    margin-top: .5em;
}

.message-decor {
	margin-bottom: 10px;
}

.message-blurb {
	font-size: 80%;
	margin-top: 1em;
}

.secondary-message.media {
	padding-bottom: 10px;
}