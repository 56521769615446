@import "mixins";

@import "normalize";

@import "colors";

@import "syntax";
 
//@import "project-setup";
 


/* ==========================================================================
   HTML5 Boilerplate styles - h5bp.com (generated via initializr.com)
   ========================================================================== */

html,
button,
input,
select,
textarea {
    color: $fgcolor;
}

body {
    font-size: 1em;
    line-height: 1.4;
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

img {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}


.chromeframe {
    margin: 0.2em 0;
    background: $ienoticebg;
    color: $ienoticefg;
    padding: 0.2em 0;
}

/* ===== Initializr Styles ==================================================
   Author: Jonathan Verrecchia - verekia.com/initializr/responsive-template
   ========================================================================== */

body {
	font-family: "proxima-nova", "helvetica neue", helvetica, arial, sans-serif;
}

.homepage-message h4 {
    font-size:200%;
    color: $message;
    line-height: 70px;
    margin-left:50px;
    padding-top:28px;
    padding-right:15px;
    margin-top:28px;
    // text-transform:lowercase;
}

.homepage-message {
    position:relative;
}

.homepage-message .iphone {
    position:absolute;
    top:-40px;
    right:-80px;
}

.homepage-message .iphone .phone-img {
    display:none;
   
}

.secondary-message {
  font-size: 22px;
  width: 375px;
  margin-left: 60px;

   h5 {
    font-size: 28px;
    margin: 0px;
    margin-top: 30px;
  }   

  p {
    margin: 0px;
    margin-bottom: 40px;
  } 
}

.wrapper {
    width: 90%;
    margin: 0 5%;
}

#first-list {
  li {
    white-space: nowrap;
  }
}

.footer-container {
    clear:both;
    background:url(../img/footer-container-background-image.png) top center;
    margin-top:20px;

    a, a:link, a:active, a:visited {
      color: inherit;
      text-decoration: none;
    }
}

.footer-container footer {
    color: $footerfg;
    height:40;
    background:url(../img/footer-background-image.png) bottom left no-repeat;   
}


/* ===================
    ALL: Orange Theme
   =================== */

$graybg: #e2e2e2;

.header-container {
    background:url(../img/header-background-image.png) top right no-repeat;   
    padding-top:20px;
}

.header-container .wrapper {
}

.header-container h1 a {
    background:url(../img/zumero-logo.png) no-repeat;
    width:162px;
    height:34px;
    display:block;
    
}

.header-container h1 a:hover {  
    background-position:0 -34px;
}

.header-container h1 a span {
    display:none;
}

.footer-container {
}

.homepage-message .main-message {
    height: 200px;
    background:url('../img/message-background-image.png') no-repeat;
}

.shortheader {
  height: 18px;
  background: url('../img/message-background-image-short.png') no-repeat;
}

.main-container {
}

.main-container section {
    background:  url('../img/main-container-gray-box.png') -620px bottom no-repeat;
    width: 306px;
    height: 200px;
    float:left;
    margin-right:20px;
}



.main-container section:last-child {
    margin-right:0px;
}

.main-container section h2 {
    text-align:center;
    padding:10px 15px;
    margin:0px;
}

.main-container section h2.tab {
    background-color: $tabbg;
    color: $tabfg;
}

.main-container section p {
    font-size:18px;
    padding:20px;
   margin:0px;
}

.title {
    color: $tabfg;
}

.footer-container {
    clear:both;
    background:url(../img/footer-container-background-image.png) top center;
    margin-top:20px;
}

.footer-container footer {
    color: $footerfg;
    height:40;
    background:url(../img/footer-background-image.png) bottom left no-repeat;   
}

h1, h2 {
  a, a:link, a:visited, a:active, a:hover {
    text-decoration: none;
    color: inherit;
    font-weight: inherit;
  }
}

/* ==============
    MOBILE: Menu
   ============== */


nav ul {
    margin: 0;
    padding: 0;
    float:right;
}

nav li {
    float:left;
}

nav a {
    display: block;
    text-align: center;
    text-decoration: none;
    font-weight:400;
    padding:5px 14px 16px 14px;
    font-size:18px;
    color: $mobilenavfg;
    text-shadow:1px 1px 2px #AAA;
}

nav li.active a {
    font-weight:700;
    background:url("../img/header-nav-arrow.png") no-repeat bottom center;
    color: $mobilenavactive;
}

nav a:visited {
    color: $mobilenavfg;
    
}

nav a:hover {
    text-decoration: none;
    margin-top: -2px;
    -webkit-transition: margin 0.5s ease-out;
    -moz-transition: margin 0.5s ease-out;
    -o-transition: margin 0.5s ease-out;
    color: $mobilenavhover;
    -webkit-transition:color .4s;
}



/* ==============
    MOBILE: Main
   ============== */


.main {
    padding: 30px 0;
}

.main article h1 {
    font-size: 2em;
}

.main-container {
    height:240px;
}

.main-container a:link {
    font-weight:bold;
}

.main-container a:visited {
    color: $linkfg;
    font-weight:bold;
}

.main-container a:hover {
    text-decoration: underline;
    //margin-top: -2px;
    -webkit-transition: margin 0.5s ease-out;
    -moz-transition: margin 0.5s ease-out;
    -o-transition: margin 0.5s ease-out;
    color: $linkhover;
    -webkit-transition:color .4s;
    }
    
p a:link {
    font-weight:bold;
    color: $linkfg;
}

p a:visited {
    color: $linkfg;
    font-weight:bold;
}

p a:hover {
    text-decoration: underline;
    //margin-top: -2px;
    -webkit-transition: margin 0.5s ease-out;
    -moz-transition: margin 0.5s ease-out;
    -o-transition: margin 0.5s ease-out;
    color: $linkhover;
    -webkit-transition:color .4s;
}

.footer-container {
    clear:both;
    background-color: $footerbg;
    margin-top:20px;
}

.footer-container footer {
    color: $footerfg;
    height:40;
    background-color: $footerbg;
}

.footer-container ul {
    list-style-type: none;
    font-size:12px;
    text-align:center;
    padding:20px 0px;
    margin-left:0px;
    padding-left:0px;
}

.footer-container ul li {
    display: inline;
    margin-left:20px;
    
}


/* ===============
    ALL: IE `xes
   =============== */

.ie7 .title {
    padding-top: 20px;
}

/* ==========================================================================
   Author's custom styles
   ========================================================================== */

a, a:link, a:active, a:visited {
  // color: $linkfg;
  font-weight: bold;
}

   .inside-page {
   } 
   
   .inside-page .header {
       padding-right:25px;
       background:url('../img/message-background-image-tall.png') no-repeat;
   }

   .button {
        box-shadow: 0px, 1px, 0px, #fff, true ;
        @include gradient(#ededed, #dfdfdf);
        border-radius: 4px;
        border:1px solid #dcdcdc;
        display:inline-block;
        color:$buttonfg;
        font-family:"proxima-nova", "helvetica neue", helvetica, arial, sans-serif;
        font-size:14px;
        font-weight:700;
        padding:3px 5px;
        text-decoration:none;
        text-shadow:1px 1px 0px #ffffff;
        margin-left: 9px;

        &:hover {
            @include gradient(#dfdfdf, #ededed);
      }
   
      &:active {
          position:relative;
          top:1px;
      }

      @media only screen and (min-width: 960px) {
        font-size:15px;
        padding:6px 24px;
        border-radius: 6px;
        margin-left: 12px;
      }      
   }
   

   h3 {
       margin-top:25px;
       margin-bottom:0px;
   }
   
   .inside-page.features .header h3 {
       color: $featureheaderfg;
       font-size:50px;
       line-height:50px;
       margin-left:60px;
       margin-bottom:0px;
       padding-top:15px;
   }
   
   h5 {
       margin:0px;
   }
   
   .inside-page.features .header h5 {
       color:$featureheaderfg;
       font-size:35px;
       line-height:40px;
       margin-left:60px;
       margin-bottom:0px;
       padding-top:15px;
       padding-bottom:15px;  
   }
   
   h4 {
     margin-top:25px;
     margin-bottom:0px;
   }
   
   .inside-page.features .header h4 {
       color:$featureheaderfg;
       font-weight:400;
       margin-left:60px;
       padding-bottom:20px;
   }
   
   
   .inside-page.features h2 {
       margin-bottom:0px;
       text-align:left;
       display:block;
   }
   
   .inside-page.features h2.tab {
       background-color:$tabbg;
       color:$tabfg;
       padding:10px;        
   }
   
    .inside-page.features p {
        margin-top:0px;
    }
    
    
    .get-started ol {
        padding-top:10px;
        
        margin-top:0px;
    }
    
    .get-started ol li {
        margin-bottom:10px;
    }

.section-textbox {
  background: $graybg url('../img/main-container-gray-box.png') right bottom no-repeat ;
  margin-bottom:20px;
  padding:20px;
  padding-right:30px;
  padding-bottom:50px;
  max-width:960px;
  overflow: auto;

  ul {
    margin-top:0px;
    list-style-type: none;
    margin-bottom:20px;
    padding:20px;
    padding-right:30px;
    padding-bottom:50px;
    max-width:960px;

    &.seealso {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  a:link {
    font-weight:bold;
    color: $linkfg;
  }

  a:visited {
    color:$linkfg;
    font-weight:bold;
  }

  a:hover {
    text-decoration: underline;
    color:$linkhover;
    -webkit-transition:color .4s;
  }

  .aside {
    float: right;
    margin-left: 40px;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
    
.homepage-message {
  h4 {
    font-size:200%;
    line-height:120%;

    br{
      display:none;
    }
  }

  .main-message {
    height: 170px;    
  }
}    

.main-container {
  section {
    width:100%;
    background:url('../img/main-container-gray-box.png') right bottom no-repeat ;
    margin-bottom:20px;

    p {
      padding-right:20px;
    }

    h2 {
      background-image:none;
    }
  }
}

    .secondary-message {
         margin-left: 0px;
         width: 100%;

      br {
        display: none;  
      }
  }


   .js #nav { display: none; }
   .js .selectnav { display: block; font-size:12px; margin-top:32px;}

   ul.fineprint {
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;

    li {
      margin-bottom: 1em;

      &:last-child {
        margin-bottom: 0;
      }      
    }

    @media only screen and (min-width: 768px) {
      margin-left: 120px;
      margin-right: 100px;
    }

   }

   
   .pricing-table {
       padding:40px 40px 40px 20px;

        @media only screen and (min-width: 784px) {
         td, th {
          font-size: 16px;
        }
       }
       
        @media only screen and (min-width: 960px) {
         td, th {
          font-size: 22px;
        }
      }

      &.narrow {
        padding: 10px;
      }

      dt, dd {
        font-size: 16px;
        padding-left: 0;
        margin-left: 0;
        font-style: normal;
      }

      dt {
        font-weight: bold;
//        border-bottom: 1px solid #999;
      }

      dd {
        margin-top: 0;
        margin-bottom: 1em;
      }

      dl {
        padding-top: 0;
        margin-top: 0;
      }
    }


    .narrow {
    }
    .wide {
      display: none;
    }

    @media only screen and (min-width: 420px) {
      .narrow { display: none; }
      .wide { display: block; }
    }

    .pricing {
      width: 100%;

      td,  th {
        text-align: left;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
      }
    }
   
   
   .pricing-header td {
       border-right: 0 !important;
   }

   .pricing-header {
       font-weight: 700;
       border-bottom: 1px solid #555;
       text-transform: uppercase;
   }

   .tellink {

    text-decoration: none;

    &, &:link, &:visited, &:active {
      font-weight: normal !important;
    } 

   }
   
   .data {
       font-weight: 700;
   }
   
   #flowchart-img img {
       width: 100%;
   }
   
   ul, ol {
       margin: 0px;
       margin-bottom: 10px;
   }
    ul ul, ol ol {
        margin-bottom: 0px;
    }
   
    h1 {
        line-height: .9em;
    }
    
    //  blockquote {
    //        font-style: italic;
    //      border-left:3px #333 solid;
    //      padding-left:20px;
    //  }

    blockquote {
        font-style: italic;
        margin: 0.25em 0;
        padding: 0.25em 0;
        line-height: 1.45;
        position: relative;
        color: $blockquotemobile;
      }

      @media only screen and (min-width: 480px) {
        blockquote {
          padding: 0.25em 40px;

          &:before {
            display: block;
            content: "\201C";
            font-size: 80px;
            position: absolute;
            left: -10px;
            top: -20px;
            color: $blockquotefg;
        }
      }
      }

   dt {
        margin-top: 10px;
    }
    
    dd {
        font-style: italic;
    }

    h6 {
        margin: 0px;
    }
    
    pre {
        border: 1px solid #333;
        background-color: $prebg;
        max-width: 80%;
        padding: 20px;
    }

    .highlight pre {
      border: none;
      background-color: transparent;
      max-width: auto;
      padding: 5px;
    }

    .designcredit {
      padding-top: 1em;
      font-size: 90%;

      a {
        font-variant: small-caps;
        text-decoration: none;
      }
    }

    .about-header {
      border-bottom: 1px solid #999;
      margin-bottom: .5em;
    }

    .caveat {
      font-size: 90%;
      font-style: italic;
    }

.notice {
  font-size: 90%;
  font-weight: bold;
}

.free {
  font-weight: bolder;
  text-transform: capitalize;
}

.table .title {
	color: #000;
	display: block;
	float: right;
	width: 100%;
}

@import "docs";
@import "blog";
@import "promo";
@import "media";
@import "customers";
@import "responsive-carousel";
@import "carousel";

/* ==========================================================================
   Media Queries
   ========================================================================== */

@media only screen and (min-width: 0px) {

   
   .pricing-table {
       padding: 40px 40px 40px 15px;
    }
    
    #pricing {
        font-size: 11px;
    }
    
      
      h1.title {
          float: left;
          width: 60%;
      }

   .inside-page.features .header h3 {
       color: $featureheaderfg;
       font-size:30px;
       line-height:30px;
       margin-left:60px;
       margin-bottom:0px;
       padding-top:15px;
   }
}

@media only screen and (min-width: 480px) {

/* ====================
    PHONE: Menu
   ==================== */

   body {
          background-color: $bgcolor;
      }
      
      h1.title {
          float: left;
          width: 30%;
      }

   
      #pricing {
          font-size:13px;
      }

   .header-container h1 a {
       background:url(../img/zumero-logo.png);
       //width:223px;
       width:162px;
       //height:47px;
       height:34px;
       display:block;
   }

   .header-container h1 a:hover {  
       //background-position:0 -48px;
       background-position:0 -35px;
   }

   .header-container h1 a span {
       display:none;
   }

   .js #nav { display: none; }
   .js .selectnav { display: block; font-size:20px; margin-top:-6px;}

    nav a {
        float: left;
        margin-bottom: 0;
    }

    nav li:first-child a {
        margin-left: 0;
    }

    nav li:last-child a {
        margin-right: 0;
    }

/* ========================
    PHONE: IE Fixes
   ======================== */

   nav {
       float: right;
       width: 35%;
       margin-top:40px;
       font-size:12px;
   }

  
    nav ul li {
        display: inline;
    }

    .oldie nav a {
        margin: 0 0.7%;
    }
    
     .homepage-message .iphone .phone-img {        
         display:none;
    }
     
      nav a {
          font-size:13px;
     }
}

@media only screen and (min-width: 768px) {

/* ====================
    TABLET: CSS3 Effects
   ==================== */

      
      h1.title {
          float:left;
          width:20%;
      }

   #flowchart-img {
       text-align:center;
   }

   #flowchart-img img {
       width:718px;
       margin:0px auto;
   }

   .inside-page.features h2 {
       display:inline-table;
   }

   .inside-page.features .header h3 {
       color: $featureheaderfg;
       font-size:50px;
       line-height:50px;
       margin-left:60px;
       margin-bottom:0px;
       padding-top:15px;
   }

      .pricing-table {
          padding:40px 100px;
      }
      
   .selectnav { display: none !important; }
   .js #nav { display: block; }

     
   .wrapper {
          width: 768px;
          margin: 0 auto;
      }

    .homepage-message h4  br{
        display:block;
    }

    .secondary-message {
        margin-left: 30px;
        width: 400px

       br {
        display:block;  
      }
    }

    
    

/* ============
    TABLET: Menu
   ============ */

    .title {
        float: left;
    }

    nav {
        float: right;
        //width: 65%;
        width: 80%;
        //margin-top:35px;
        margin-top:26px;
        font-size:12px;
    }

    
    nav a {
         font-size:14px;
         padding:5px 5px 15px 10px;
     }

/* ============
    TABLET: Main
   ============ */
   .main-container section {
         width:100%;
         background:url('../img/main-container-gray-box.png') -820px bottom no-repeat ;
         margin-bottom:20px;
     }
   

    .main article {
        float: left;
        width: 57%;
    }

    .main-container section {
        width: 246px;
        height: 200px;
        margin-right:15px;
    }
    .main-container last-child {
        margin-right:0px;
    }
    
     .main-container section p {
         font-size:80%;
     }
     
     .homepage-message .iphone {
         right:36px;
         top:-60px;
     }
     
     .homepage-message .iphone .phone-img {
         background:url('../img/iphone-vertical.png') no-repeat;
         width:237px;
         height:454px;
         display:block;
     }
     
     .homepage-message .main-message {
         height:170px;
     }
     
     .homepage-message{
         height:390px;
     }
     
     .homepage-message h4 {
         font-size: 32px;
         line-height: 1.1em;
         padding-top:30px;
         padding-left:30px;
         width: 45%;
     }
     
     .secondary-message {
         width: 400px;

       p {
          font-size:90%;
      }
     }
  
    
    .footer-container {
        clear:both;
        background:url(../img/footer-container-background-image.png) top center;
        margin-top:20px;
    }

    .footer-container footer {
        color: $footerfg;
        height:40;
        background:url(../img/footer-background-image.png) bottom left no-repeat;   
    }
    
     
}

@media only screen and (min-width: 960px) {
    
    .selectnav { display: none !important; }
    .js #nav { display: block; }
    
    .wrapper {
           width: 960px;
           margin: 0 auto;
       }

   .main-container section {
       width: 306px;
       height: 200px;
       margin-right:20px;
       margin-top:30px;
   }
   
   .main-container section p {
        font-size:100%;
    }
    
    .homepage-message .iphone .phone-img {
        background:url("../img/iphone-smooth.png");
        width:584px;
        height:458px;
        display:block;
    }
    
    .homepage-message .iphone {
        position:absolute;
        top:-40px;
        right:-80px;
    }
    
    nav {
        float:right;
        // width:75%;
        width:80%;
        // margin-top:35px;
        margin-top:24px;
        font-size:12px;
    }
    
    nav a {
        font-size:18px;
        // padding:5px 12px 16px 12px;
        padding:5px 10px 16px 10px;
    }
    
    .homepage-message .main-message {
        height:200px;
        
    }
    
    .homepage-message{
        height:410px;
    }
    
     .homepage-message h4 {
         font-size: 40px;
         color: $bannerfg;
         line-height: 1.1em;
         margin-left:50px;
         padding-top:32px;
         margin-top:28px;
         // text-transform:lowercase;
         width: 45%;
         padding-left:30px;
     }
     
     .main-container section {
           background:url('../img/main-container-gray-box.png') -770px bottom no-repeat ;
           margin-bottom:20px;
       }
       
       .main-container.features section {
            color: $featuresfg;
            width:100%;
            background-color: $featuresbg;
       }
   
      .secondary-message {
          width: 400px;
          margin-left:30px;
       p {
           font-size: 100%;
        }
      }

     
     
}

@media only screen and (min-width: 1140px) {

/* ===============
    Maximal Width
=============== */
body {
  }
  
} 

/* ==========================================================================
   Helper classes
   ========================================================================== */

.ir {
    background-color: transparent;
    border: 0;
    overflow: hidden;
    *text-indent: -9999px;
}

.ir:before {
    content: "";
    display: block;
    width: 0;
    height: 150%;
}

.hidden {
    display: none !important;
    visibility: hidden;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.invisible {
    visibility: hidden;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}



/* ==========================================================================
   Print styles
   ========================================================================== */

@media print {
    * {
        background: transparent !important;
        color: #000 !important; /* Black prints faster: h5bp.com/s */
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    /*
     * Don't show links for images, or javascript/internal links
     */

    .ir a:after,
    a[href^="javascript:"]:after,
    a[href^="#"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group; /* h5bp.com/t */
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    @page {
        margin: 0.5cm;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}
