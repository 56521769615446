$fgcolor: #222;
$bgcolor: #fff;

$headerbg: #030303;
$headerfg: #FCFCFC;

$topheadbg: #f15829;

$asidebg: darken($topheadbg,30%);
$asidefg: #F3F3F3;

$bannerbg: #f15829;
$bannerfg: #fff;

$footerfg: #FFF;
$footerbg: #000;

$screenbg: #cccccc;
$screenbold: green;

$postdatebg: $asidebg;
$postdatefg: $asidefg;

$tabborder: #e2e2e2;
$activetab: $topheadbg;
$inactivetab: #666;
$tabbg: #000;
$tabfg: #FFF;

$articleheaderfg: #FFF;
$articleheaderbg: #333;
$permalink: $articleheaderfg;

$listheadfg: #F0F0F0;
$listheadbg: $headerbg;

$toclink: #333;

$message: #FFF;

$ienoticefg: #000;
$ienoticebg: #ccc;

$mobilenavfg: #545454;
$mobilenavactive: #000;
$mobilenavhover: #F15829;

$linkfg: #000;
$linkhover: #7f7f7f;

$buttonfg: #777777;

$featureheaderfg: #FFF;
$featuresfg: #990000;
$featuresbg: #eee;

$blockquotemobile: #383838;
$blockquotefg: #7a7a7a;

$prebg: #eee;

