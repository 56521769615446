$zorange: #f15829;

/* ====== media ====== */
.media {margin:10px;}
.media, .bd {overflow:hidden; _overflow:visible; zoom:1; }
.media .img {float:left; margin-right: 10px;}
.media .img img{display:block;}
.media .imgExt{float:right; margin-left: 10px;}

.main-container.media-container {
	height: auto;
	margin-top: 30px;
}

.howtodesc {
	min-width: 250px;
}

.bd {
	h3 {
		margin-top: 0;
	}
}

.media-container {
	.img {
		box-shadow: rgba(0,0,0,0.15) 0 1px 4px;
		padding: 10px;
		margin-top: 30px;
		box-sizing: border-box;
		width: 100%;

		@media only screen and (min-width: 768px) {
			width: 50%;
		}

	}

	.bd {
		padding: 10px;

		p {
			font-size: 120%;
		}

		img {
			display: block;
			margin-left: auto;
			margin-right: auto;

			@media only screen and (min-width: 768px) {
				margin-left: 0;
			}
		}
	}
}

figure {
	&.shot {
		img {
			box-shadow: rgba(0,0,0,0.15) 0 1px 4px;
			padding: 0;
			box-sizing: border-box;
		}

		margin-bottom: 1em;
		margin-top: .25em;

		@media only screen and (min-width: 768px) {
			float: right;
			margin-left: 1em;
		}
	}
}

h3 {
	clear: both;
}

figcaption {
	text-align: center;
	margin-top: .5em;
	font-style: italic;
	color: $zorange;
}

aside {
	figure {
		text-align: center;
	}
	figcaption {
		color: $asidefg;
	}
}

.diagram {
	max-width: 100%;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.insetcontainer {
	overflow: auto;
		box-sizing: border-box;
}

.insettext {
	@media only screen and (min-width: 768px) {
		float: left;
		width: 50%;
		margin-right: 0;
		box-sizing: border-box;
	}

}
.insetimage {
	width: 100%;
	margin-bottom: 1em;

	@media only screen and (min-width: 768px) {
		float: left;
		padding: 0 1em 1em 1em;
		width: 50%;
		margin-left: 0;
		box-sizing: border-box;
	}
}

.tutorial {
	width: 100%;
	margin-bottom: 1em;
	padding: 0;
	box-sizing: border-box;

	@media only screen and (min-width: 768px) {
		padding: 0 1em 0 0;
	}	
}

@import "lightbox";
